exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-addresses-jsx": () => import("./../../../src/pages/account/addresses.jsx" /* webpackChunkName: "component---src-pages-account-addresses-jsx" */),
  "component---src-pages-account-index-jsx": () => import("./../../../src/pages/account/index.jsx" /* webpackChunkName: "component---src-pages-account-index-jsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-orders-jsx": () => import("./../../../src/pages/account/orders.jsx" /* webpackChunkName: "component---src-pages-account-orders-jsx" */),
  "component---src-pages-account-register-tsx": () => import("./../../../src/pages/account/register.tsx" /* webpackChunkName: "component---src-pages-account-register-tsx" */),
  "component---src-pages-account-reset-password-tsx": () => import("./../../../src/pages/account/reset-password.tsx" /* webpackChunkName: "component---src-pages-account-reset-password-tsx" */),
  "component---src-pages-account-settings-jsx": () => import("./../../../src/pages/account/settings.jsx" /* webpackChunkName: "component---src-pages-account-settings-jsx" */),
  "component---src-pages-account-verify-account-jsx": () => import("./../../../src/pages/account/verify-account.jsx" /* webpackChunkName: "component---src-pages-account-verify-account-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-dog-calorie-calculator-jsx": () => import("./../../../src/pages/dog-calorie-calculator.jsx" /* webpackChunkName: "component---src-pages-dog-calorie-calculator-jsx" */),
  "component---src-pages-first-aid-guide-download-jsx": () => import("./../../../src/pages/first-aid-guide-download.jsx" /* webpackChunkName: "component---src-pages-first-aid-guide-download-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kurgo-pro-program-application-index-jsx": () => import("./../../../src/pages/kurgo-pro-program-application/index.jsx" /* webpackChunkName: "component---src-pages-kurgo-pro-program-application-index-jsx" */),
  "component---src-pages-lifetime-warranty-form-index-jsx": () => import("./../../../src/pages/lifetime-warranty-form/index.jsx" /* webpackChunkName: "component---src-pages-lifetime-warranty-form-index-jsx" */),
  "component---src-pages-logout-jsx": () => import("./../../../src/pages/logout.jsx" /* webpackChunkName: "component---src-pages-logout-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-templates-article-category-listing-jsx": () => import("./../../../src/templates/article-category-listing.jsx" /* webpackChunkName: "component---src-templates-article-category-listing-jsx" */),
  "component---src-templates-article-detail-jsx": () => import("./../../../src/templates/article-detail.jsx" /* webpackChunkName: "component---src-templates-article-detail-jsx" */),
  "component---src-templates-article-listing-jsx": () => import("./../../../src/templates/article-listing.jsx" /* webpackChunkName: "component---src-templates-article-listing-jsx" */),
  "component---src-templates-collections-jsx": () => import("./../../../src/templates/collections.jsx" /* webpackChunkName: "component---src-templates-collections-jsx" */),
  "component---src-templates-marketing-page-jsx": () => import("./../../../src/templates/marketing-page.jsx" /* webpackChunkName: "component---src-templates-marketing-page-jsx" */),
  "component---src-templates-product-detail-jsx": () => import("./../../../src/templates/product-detail.jsx" /* webpackChunkName: "component---src-templates-product-detail-jsx" */)
}

